a.venta-option:hover {
    cursor: pointer;
}

div.row+div.venta {
    border-radius: 7px 7px 0px 0px;
}

div.venta:last-of-type {
    border-radius: 0px 0px 5px 5px;
}

div.venta {
    max-width: none;
}

.acciones-ventas button {
    color: #ffffff;
    font-weight: 600;
}

.acciones-ventas button.confirmar+button.cancelar {
    margin-top: 3px;
}

button.confirmar {
    background-color: #0084ff;
}

button.cancelar {
    background-color: #ff0040;
}